
          @import "@/assets/css/vars.scss";
        






































































.pagination {
  padding: 20px 40px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 120px;
    cursor: pointer;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 20px;
      color: #585e6d;
    }
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    .currently-displayed {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #394050;
    }
    .total {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #9da0a6;
      margin: 0 20px 0 10px;
    }
    .controls {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #6a717d;
      cursor: pointer;
      span {
        &:hover {
          color: #9da0a6;
        }
      }
    }
  }
}
