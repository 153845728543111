
          @import "@/assets/css/vars.scss";
        



















































































































































.confirmation-modal {
  width: 50%;
  position: absolute;
  height: 662px;
  top: 5.5%;
  right: 0;
  padding: 20px;
  background: #f9f9fa;
  .top {
    width: 100%;
    height: 50%;
    height: 380px;
    margin-bottom: 30px;
    overflow: auto;
    img {
      display: block;
      width: 100%;
    }
  }
  .bottom {
    .info {
      display: flex;
      align-items: center;
      justify-content: space-around;
      & > div {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #81858f;
        margin-bottom: 10px;
      }
      .info-data {
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        color: #394050;
        margin-top: 10px;
      }
    }
  }
  .controls {
    margin-top: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    label {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #585e6d;
    }
    .selected-gift-wrap {
      height: 100%;
      margin: 0 20px;
      span {
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
    .input-wrap {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      margin-right: 25px;

      select {
        width: 281px;
        height: 54px;
        background: #ffffff;
        border: 1px solid #e2e2e2;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        color: #ff6700;
        &:focus {
          outline: none;
        }
      }
    }
    .checkbox-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      input {
        width: 24px;
        height: 24px;
        background: #ffffff;
        border: 1px solid #c6c6c6;
        box-sizing: border-box;
        border-radius: 5px;
        margin-right: 10px;
      }
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        color: #394050;
      }
    }
  }
}
