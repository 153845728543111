
          @import "@/assets/css/vars.scss";
        



















































.confirmation-modal {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background: #fff;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .dialog {
    width: 473px;
    height: 273px;
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 30px;
      text-align: center;
      color: #394050;
      margin: 20px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: #394050;
      margin-bottom: 40px;
    }
    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .confirm-btn {
        width: 145px;
        height: 54px;
        background: #f96600;
        border-radius: 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.01em;
        border: none;
        color: #ffffff;
        cursor: pointer;
      }
      .cancel-btn {
        width: 145px;
        border: none;
        height: 54px;
        background: #fff;
        border-radius: 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #f96600;
        cursor: pointer;
      }
    }
  }
}
