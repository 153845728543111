
          @import "@/assets/css/vars.scss";
        




















#home-page {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .logo-container {
    margin-bottom: 20px;
    img {
      display: inline-block;
      width: 60px;
    }
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.01em;
    color: $text-gray;
    margin-top: 40px;
    span {
      color: $orange;
    }
  }
}
